
.navbar {
    margin-bottom: 0;

    .navbar-brand {
        a {
            color: @navbar-inverse-color;
            &:hover {
                color: @navbar-inverse-link-hover-color;
            }
        }
    }
}
