@charset "UTF-8";

// libraries and browser issues..
@import "bfp-bootstrap";
//@import "../../../../lib/dkjs/css/dkjs.less";
@import "browser-hacks";

// theming (colors/fonts/sizes)
@import "bfp-variables";
@import "bfp-theme";

// layout (page)
@import "layout";
//@import "flex-site";

// elements (innermost at top)
@import "bfp-elements";
@import "navbar";
@import "footer";
@import "progress-tag";
@import "bfp-panels";
@import "img-upload";

// page-specific
@import "home-page";
@import "modal";
@import "profile";


// --------------- to be moved -----------

.button.row {
  > * {
    margin-bottom: @grid-gutter-width;
  }
}

.striped > section + section:before {
  // add a horizontal border gradient between sections
  border: none;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, black, transparent);
}


.branding {
  position: relative;
  .bg-image-small;
  height: @branding-height-subpage;
  min-height: 71px;
  margin-top: 0;

  .batforerregisteret {
    bottom: 0;
    left: @grid-gutter-width;
    line-height: .8;
  }
}


.need-explanation {
  margin-top: 25px;
  border-top: 1px dotted #ccc;
  padding-top: 15px;
  font-size: 85%;
  line-height: 120%;
  color: #696969;
}

.bottom {
  position: fixed;
  bottom: 5px;
  right: 5px;
}


.page-logo {
  background: #417690 url("https://static.datakortet.no/bfpregistration/images/redboat-thin.jpg");
  background-size: cover;
  background-position: center; /* IE fix */
  position: relative;

  #hd {
    height: 70px;
  }

  #hd .logo {
    font-size: 22pt !important;
  }

  @media (max-width: 479px) {
    #hd {
      height: 70px;
    }

    #hd .logo {
      font-size: 18pt !important;
    }
  }

  .logo {
    color: white;
    cursor: default;
    position: absolute;
    left: 1em;
    bottom: 0.5em;
    white-space: nowrap;
  }

  .logo span.nt {
    color: white;
    font-size: 120%;
    margin: -.5ex;
    display: inline-block;
  }

}

.testcenter-image {
  width: 100%;
}

.introduction {
  .btn.min-side {
    font-size: 120%;
    margin-top: 15px;
    margin-bottom: 7.5px;
    box-shadow: 0 0 30px 5px rgba(255, 255, 255, 0.5);
    display: block;
    width: 100%;
  }
}

.image-container {
  display: flex;
  justify-content: center;
}
