@import "bfp-variables";

@bfp-progress-complete-color: lighten(@bfp-ok-color, 10%);
@bfp-progress-active-color: lighten(desaturate(blue, 50%), 10%);
@bfp-progress-base-color: #ededed;

.progress {
    margin-bottom: 14px;
    display: flex;
    justify-content: center;
    gap: 2px;

    li {

        // width: 19%;
        // max-width: 100px;
        .check {
            position: relative;
            z-index: 20;
            font-size: 14px;
            line-height: 1;
            top: 20px;
            visibility: hidden;
        }

        // margin-right: 2px;

        a {
            text-align: center;
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            width: 100px;

            dk-icon {
                font-size: 2.5rem;
                display: block;
                // margin-left: 25%;
            }
        }
    }

    .green .check {
        visibility: visible;
    }

    .active .check {
        visibility: visible;
        color: white;
    }

    .active.green a {
        background-color: @bfp-progress-complete-color !important;
    }

    li a {
        background-color: @bfp-progress-base-color;
    }

    li.active a {
        background-color: @bfp-progress-active-color !important;
    }

    li a:before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        border: 8px solid transparent;
        margin-top: -8px;
        border-left-color: white;
        content: "";
    }

    li a:after {
        display: inline-block;
        position: absolute;
        left: 99.9%;
        top: 50%;
        border: 8px solid transparent;
        border-left-color: @bfp-progress-base-color;
        margin-top: -8px;
        content: "";
        z-index: 20;
    }

    li.active a:after {
        border-left-color: @bfp-progress-active-color;
    }

    li.green a:after {
        border-left-color: @bfp-progress-complete-color;
    }

    li.first a:before,
    li.last a:after {
        border: none;
    }

    @media (max-width: @screen-xs-max) {
        li a {
            width: unset !important;
        }
    }

    @media (max-width: @screen-sm-max) {
        .step-name {
            display: none;
        }

        li a dk-icon {
            font-size: 18px;
        }
    }
}
