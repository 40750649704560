// -*- coding: utf-8 -*-

.attribution.container {
    // This is the block that contains the nt-logo, the sj.dir. logo,
    // and the attribution notice.

    margin-bottom: @grid-gutter-width / 2;

    small.centered {
        // the text "Båtførerregisteret driftes av ..."
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        text-align: center;
        font-family: "Courier New";
    }
}
