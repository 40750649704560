
.dvImageInput {
    position: relative;
    overflow: hidden;
    margin-left: @grid-gutter-width / 2;

    .helptext {
        display: none;
    }

    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        background: white;
        cursor: inherit;
        display: block;
        outline: none;
    }
}
