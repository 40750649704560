@import "bfp-variables";

/*  NOTE: Keep these rules separated by topic..
*/

// smallest reasonable width
html { min-width: 320px; }

/*! FIXED footer rules
*/
html {
    position: relative;
    min-height: 100%;

    body {
        margin-bottom: @footer-height;
        padding-bottom: 200px;

        header + main { margin-top: @grid-gutter-width; }

        &>footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: @footer-height;
        }

        &.popup {
            padding-bottom: 50px;
            margin-bottom: @footer-height / 3;
            &>footer { height: @footer-height / 3; }
        }

        &.modal {
            padding-bottom: 0;
            margin-bottom: @footer-height / 2;
            &>footer { height: @footer-height / 2; }
        }

    }
}
