
@import "bfp-variables";

.btn.min-side {
    .button-variant(@min-side-color, @min-side-bg, @min-side-border);
}

.btn.demotest {
    .button-variant(@demotest-color, @demotest-bg, @demotest-border);
}

.batforerregisteret,
.batforerregisteret:focus,
.batforerregisteret:hover {
    // Styling for the text "Båtførerregisteret" when used as a heading.
    // this class should be set on the innermost element.
    color: white;
    text-decoration: none;
    font-family: Inconsolata, Georgia, "Times New Roman", times, serif;
    // white-space: nowrap;  // Båtfører-registeret.


}
.batforerregisteret.shadowed {
    // add this class when the background image makes the contrast too weak:
    // e.g.: <h1 class="batforerregisteret shadowed">Båtførerregisteret</h1>
    text-shadow: @jumbotron-text-shadow;
}

.btn-edit {
    .button-variant(#fff, desaturate(darken(#1D9E74, 3%), 30%), darken(#1D9E74, 5%));
}

.panel {
    .panel-heading {
        .btn-edit {
            .btn-xs;
            .pull-right;
        }
        .btn-edit:after {
            content: "..";
        }
    }
}

#duplicate-btn {
    margin-top: 5px;
}
