//@state-danger-text: #a94442;
//@state-danger-bg: #f2dede;
//@state-danger-border: darken(spin(@state-danger-bg, -10), 5%);

@staff-text: white;
@staff-bg: red;
@staff-border: darken(spin(@staff-bg, -10), 5%);

.panel-admin {
    .panel-variant(@staff-border; @staff-text; @staff-bg; @staff-border);
}

@tl-text: white;
@tl-bg: orange;
@tl-border: darken(spin(@tl-bg, -10), 5%);

.panel-tl {
    .panel-variant(@tl-border; @tl-text; @tl-bg; @tl-border);
}

@ka-text: #333;
@ka-bg: desaturate(lighten(green, 45%), 75%);
@ka-border: darken(spin(@ka-bg, -10), 5%);

.panel-ka {
    .panel-variant(@ka-border; @ka-text; @ka-bg; @ka-border);
}
