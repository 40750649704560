
/*
    Styles that are special when they're on the home page.
*/
@import "bfp-variables";
@import "bfp-theme";
@import "attribution";

.home {

    .jumbotron {
        color: @jumbotron-font-color;

        @media (min-width: @screen-sm-min) {
            padding: 120px 0;
        }

        &.header {
            // This is the topmost jumbotron on the home page,
            // containing the background image and call to action.
            .bg-image-big;
            text-shadow: @jumbotron-text-shadow;
            margin-bottom: @jumbotron-spacing;

            @media (max-width: @screen-sm-max) {
                h2 { font-size: @font-size-large; }
                .description p { font-size: 100%; }
            }
        }

        &.veien-til-bevis {
            // This is the second jumbotron on the home page,
            // containing the enumerated steps.

            h2 {
                // The header for the second jumbotron.
                &:extend(.jumbotron .h1);
                text-align: center;
            }
            h3 {
                a {
                    color: inherit;
                    .numcircle {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    section.row {

        > article,
        > .article {
            .make-sm-column(7);
        }
        > aside,
        > figure,
        > .aside {
            .make-sm-column(5);
        }

        margin-bottom: @grid-gutter-width;

        > h2 {
            // this is &:extend(.col-xs-12)
            .make-xs-column(12);
            margin-bottom: @grid-gutter-width;
            float: none;
        }

        > aside {
            > h3:first-child {
                // this makes columns align at the top when they start with a h3
                margin-top: 0;
            }
        }
    }


    .btn-call-to-action {
        // the button we want everyone to click
        &:extend(.btn-lg, .btn-primary, .btn-block);
        font-size: 120%;
        margin-top: @grid-gutter-width / 2;
        margin-bottom: @grid-gutter-width / 4;
        box-shadow: 0 0 30px 5px rgba(255, 255, 255, 0.5);
    }
    .btn-secondary-call-to-action {
        &:extend(.btn-warning);
        width: 100%;
        color: #212529;
        box-shadow: 0 0 30px 5px rgba(255, 255, 255, 0.5);
        text-shadow: none;
    }

    .popover.selvtest {
        // the bootstrap popover components for buttons under exam
        height: 220px;
    }

}

.cand-info {
    .ci-th {
        font-weight: bold;
        font-size: large;
        margin-top: 30px;
    }
}

@media print {
    header, footer  {
        display: none !important;
    }
    body {
        margin: 0;
        color: #000;
        background-color: #fff;
    }
}
