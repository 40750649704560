
html {
    position: relative;
    min-height: 100%;

    body.modal {
        .bg-image-big;
        margin: 0;
        height: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .modal-main {
            z-index: 50;
            background-color: rgba(255, 255, 255, .9);
            border: 2px solid #000;
            margin-top: -3%;

            &::after {
                content: '\00a0';
                display: block;
                height: 0;
                visibility: hidden
            }
        }
    }
}
