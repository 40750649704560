@import "bfp-variables";

@footer-logo-height: 55px;


body > footer.footer {

    .spacer.row {
        height: 50px;
        &.visible-xs-block { height: 25px; }
    }

    background: @footer-background-color;
    color: @footer-font-color;
    font-family: @footer-font-family;
    font-size: @footer-font-size;
    font-weight: normal;

    a {
        color: @footer-font-color;
    }

    ul {
        padding-left: 1em;
        li {
            list-style: none inside;
            margin-top: 1ex;
        }
    }
    header {
        margin-bottom: 14px;

        .nt-logo-combined {
            @man-size: @footer-logo-height;
            img {
                display: inline;
                vertical-align: baseline;
                //outline: 1px solid white;
            }
            .man {
                  display: block;
                  position:absolute;
            }
            .text {
                margin-left: -4px;
            }

            .logo-size(@base-size: 50px) {
                @logo-text-height: 0.5454 * @base-size;
                .man {
                    left: (-1 * @base-size + @logo-text-height / 4);
                    top: (-1 * (@base-size - @logo-text-height) / 2);
                    height: @base-size;
                    width: @base-size;
                }
                .text {
                    height: @logo-text-height;
                }
            }

            .logo-size(@footer-logo-height);


            @media (max-width: @screen-sm-max) {
                .logo-size(0.75 * @footer-logo-height);
            }
            @media (max-width: @screen-xs-max) {
                .logo-size(0.6 * @footer-logo-height);
            }
        }

    }

    .footer-menu > div {
        margin-top: 24px;
        font-size: 85%;
    }

    @media (min-width: @screen-md-min) {
        .footer-menu > div {
            margin-top: @footer-logo-height;
            font-size: 100%;
        }
    }
    .legal {
        font-size: 80%;
        position: absolute;
        left: 14px; bottom: 14px;

        a { margin: 14px; }
    }
}
