/*
    THEME/display rules.

     yes: colors, font-*

     no:  width/height/position.
*/

@import "bfp-variables";

.bfp-ok { color: @bfp-ok-color; }
.bfp-fail { color: @bfp-fail-color; }


.bg-image-big {
    background: url('https://static.datakortet.no/bfpregistration/images/redboat.jpg') right / cover;
}

.bg-image-small {
    background: url('https://static.datakortet.no/bfpregistration/images/redboat-thin.jpg') right / cover;
}
