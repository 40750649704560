/*
Usage:

    <h4 class="chevron-toggle" data-toggle="collapse" href="#target-id">
        <dk-icon value="chevron-down:fw"></dk-icon>
        heading-text
    </h4>
*/
.chevron-toggle {
  dk-icon[value^=chevron-down] {
    transition: transform .5s;
  }

  &.collapsed dk-icon[value^=chevron-down] {
    transform: rotate(-90deg);
  }
}

.identicon {
    cursor: default;
    border: 1px solid #ccc;
    padding: 7px;
    border-radius: 50%;
    background-color: #eee;
    margin-top: 2em;
    margin-right: 1em;

    &:hover {
      background-color: #ccc;
    }
    &:active {
      background-color: #555;
      box-shadow: 0 3px #fff;
      transform: translateY(4px);
    }

    >table { border: none;}

    td > div {
        width: 6px;
        height: 6px;
    }
}

.infobox {
  background-color: #fff;
  padding: 1ex;
  //border: 1px solid #bbb;
  position: relative;
  border-radius: 4px;
}

.subtle {
  color: #666;
  font-style: italic;
  font-size: 85%;
  padding-left: 1ex;
  line-height: 100%;
}

.msgbox {
  border-radius: 4px;
  border: 1px solid #bbb;
  position: relative;
  background-color: #ffffe0;
  margin: 7px 14px 18px 0;
  padding: 1ex;

}

.when {
  border: 1px solid #bbb;
  background: none repeat scroll 0 0 #ffffe0;
  border-bottom: medium none;
  color: #666;
  font-size: 85%;
  padding: 0 4px;
  position: absolute;
  top: -12px;
}

.small-details {
  font-size: 85%;
  line-height: 100%;
  text-align: right;

  strong:after {
    display: block;
    content: "";
  }
}

section.titled-section {
  border: 1px solid #bbb;
  padding: 0;
  border-radius: 5px;
  margin-bottom: 29px;
  margin-top: 10px;
  position: relative;

  > table.table {
    padding: 0;
    margin: 0 !important;
  }
}

.titled-section {

  > header > h4 {
    font-size: 14px;
    position: absolute;
    text-transform: lowercase;
    top: -21px;
    left: 19px;
    background-color: white;
    display: inline-block;
    padding: 0 7px;
  }

  .alert {
    margin-bottom: 0;

    > ul {
      padding-inline-start: 0;
    }
  }
}


.scrollable-box {
  position: relative;
  padding-top: 37px;

  .scrollable {
    overflow-y: auto;
    //height: 20vh;

    table {
      width: 100%;

      th {
        height: 0;
        line-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        white-space: nowrap;

        div {
          position: absolute;
          top: 9px;
          line-height: normal;
        }
      }
    }
  }
}

.datetime {

  .datetime-date {

  }

  .datetime-time-label {
    font-size: 92%;
    color: #666;
  }

  .datetime-time {
    .datetime-hour {
    }

    .datetime-minute {
      font-size: 92%;
    }
  }

}

.age {
  .age-label {
    font-size: 85%;
    color: #888;
  }
}

.information-sections section + section {
  margin-top: 15px;
}

.item-group {
  margin-top: 15px;
  //outline: 1px solid blue;

  .item {
    clear: both;
    min-height: 1px;
    //border-bottom: 1px dotted #999;
    overflow: hidden;

    &:last-child {
      border-bottom: none;
    }

    + .item {
      margin-top: 3px;
    }

    .key {
      //float: right;
      font-size: 85%;
      font-style: italic;
      color: #999;
      padding-right: 1ex;
    }

    .value {
    }
  }
}

.panel > .key,
.panel > .value {
  float: none;
  width: 100%;
  max-width: 100%;
}

.panel > .key::after {
  content: none;
}
